$small-border-width: 1px;
$arrow-offset: 10px;

$slide-first-visible: 3; // Starting at two makes sliding smoother.

:root {
    --slide-space: 800px;
    --slide-width: 800px;
    --button-size: 50px;
}

@media (max-width: 900px) {
    :root {
        --slide-space: 400px;
        --slide-width: 400px;
        --button-size: 25px;
    }
}

@media (max-width: 450px) {
    :root {
        --slide-space: 150px;
        --slide-width: 150px;
        --button-size: 15px;
    }
}

@mixin round-button {
    box-sizing: border-box;
    width: var(--button-size);
    height: var(--button-size);
    font: var(--button-size) "Century Gothic", Futura, sans-serif;
    line-height: var(--button-size);
    border: none;
    border-radius: calc(var(--button-size) / 2);
    padding: $small-border-width;
    margin: auto;
    cursor: pointer;
}

.slideManager {
    position: relative;
    width: min(calc(70vw - 100px), var(--slide-space));
    height: min(calc(100vw + var(--button-size) * 1.5), calc((var(--slide-space) + var(--button-size)) * 2));
    padding: 0 5px;
    overflow: hidden;

    .leftArrow,
    .rightArrow {
        @include round-button;
        position: absolute;
        top: 0;
        color: rgba(200, 200, 200, 1);
        background: rgba(10, 19, 19, 1);

        &:disabled {
            background: transparent;
            color: transparent;
        }
    }

    .leftArrow {
        left: $arrow-offset;
    }

    .rightArrow {
        right: $arrow-offset;
    }

    .slideContainer {
        display: block;
        position: relative;
        top: calc(var(--button-size) + 5px);
        width: min(calc(70vw - 100px), var(--slide-space));
        height: min(100vw, calc(var(--slide-space) * 2));
        padding: 0;
        margin: 0;
        overflow: hidden;

        .carousel-slide {
            position: absolute;
            top: 0;
            width: min(100%, var(--slide-width));

            left: calc(var(--slide-space) * 4);
            display: none;

            transition-property: left;
            transition-duration: 2s;

            >img {
                width: 100%;
            }

            &:first-child {
                display: none;
                left: calc(var(--slide-space) * -2);
            }

            &:nth-child(2) {
                display: block;
                left: calc(var(--slide-space) * -1);
            }

            @for $i from 0 through 1 {
                $child-number: $i + $slide-first-visible;

                &:nth-child(#{$child-number}) {
                    display: block;
                    left: calc($i * var(--slide-space));
                }
            }

            .info {
                display: none;
            }

            &:hover .info {
                display: block;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background-color: rgba(127, 127, 127, 0);
                color: white;
                font-size: 50px;
                line-height: 50px;
                text-align: center;
            }
        }
    }
}