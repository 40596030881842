body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: black;
  color: white;

  box-sizing: border-box;
}

a,
button {
  color: rgb(25, 25, 0);
  background-color: rgba(100, 255, 255, 0.7);
  border-radius: 5rem;
  padding: 1px .5rem;
  box-shadow: 3px -3px 2px rgba(50, 127, 127, 0.9);

  &:visited {
      color: rgb(25, 25, 50);
      background-color: rgba(100, 255, 255, 0.7);
  }

  &:active {
    color: rgb(50, 0, 25);
    background-color: rgba(255, 100, 100, 0.7);
    border: 1px solid white;
  }

  &:hover {
    border: 5px solid rgba(180, 80, 80, 0.7);
    box-shadow: 5px -3px 2px rgba(50, 127, 127, 0.9);  }
    transition: all 250ms ease-in-out;
    // transition: box-shadow 2s 10ms;
  }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  list-style: none;
}