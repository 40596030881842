$sg-color-1: rgba(63, 0, 0, 0.5);
$sg-color-2: rgba(63, 63, 0, 0.5);
$sg-color-3: rgba(63, 63, 63, 0.5);
$sg-color-4: rgba(0, 63, 63, 0.5);
$sg-color-5: rgba(0, 0, 63, 0.5);
$sg-color-6: rgba(0, 0, 0, 0.5);
$sg-color-7: rgba(0, 63, 0, 0.5);
$sg-color-8: rgba(0, 63, 63, 0.5);
$sg-color-9: rgba(63, 63, 63, 0.5);
$sg-color-10: rgba(63, 0, 63, 0.5);
$sg-color-11: rgba(63, 0, 0, 0.5);
$sg-color-12: rgba(0, 0, 0, 0.5);
$sg-color-13: rgba(0, 0, 63, 0.5);
$sg-color-14: rgba(63, 0, 63, 0.5);
$sg-color-15: rgba(63, 63, 63, 0.5);
$sg-color-16: rgba(63, 63, 0, 0.5);
$sg-color-17: rgba(0, 63, 0, 0.5);
$sg-color-18: rgba(0, 0, 0, 0.5);
$sg-colors: $sg-color-1, $sg-color-2, $sg-color-3, $sg-color-4, $sg-color-5, $sg-color-6,
  $sg-color-7, $sg-color-8, $sg-color-9, $sg-color-10, $sg-color-11, $sg-color-12,
  $sg-color-13, $sg-color-14, $sg-color-15, $sg-color-16, $sg-color-17, $sg-color-18;

.App {
  // background-image: linear-gradient(160deg, $sg-colors);
  background-color: black;
  padding: 20px;
  animation-name: shifting-gradients;
  animation-duration: 60s;
  // animation-direction: alternate;
  animation-iteration-count: infinite;
}

@keyframes shifting-gradients {
  $i: 0;
  $r: 0;
  $g: 0;
  $b: 0;
  $forward: 1;
  $colorMax: 100;
  $colorInc: calc(($colorMax * 30) / 400);

  @while ($i <=100) {
    #{$i + '% '} {
      background-image: linear-gradient(#{$i * 3.6 + 'deg'}, #{$sg-colors});
      background-color: rgb($r, $g, $b);
    }

    $i: $i + 0.25;

    @if ($forward ==1) {
      $r: $r + $colorInc;
    }

    @else {
      $r: $r - $colorInc;
    }

    @if ($r > $colorMax) {
      $r: $colorMax;
      $g: $g + $colorInc;
    }

    @if ($r < 0) {
      $r: 0;
      $g: $g - $colorInc;
    }

    @if ($g > $colorMax) {
      $g: $colorMax;
      $b: $b + $colorInc;
    }

    @if ($g < 0) {
      $g: 0;
      $b: $b - $colorInc;
    }

    @if ($b > $colorMax) {
      $b: $colorMax;
      $forward: 0;
    }

    @if ($b < 0) {
      $b: 0;
      $forward: 1;
    }
  }
}

header {
  border: 5px solid gray;
  border-radius: 1em;
  padding: 20px;
  width: calc(100vw - 100px);
  background-color: white;
  color: black;
  font-size: 30px;

  @media (max-width: 600px) {
    width: calc(85vw - 100px);
    font-size: 20px;
  }
}

nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  button {
    font-size: 2rem;
    padding: 0 1ch;
    border-radius: .5em;
  }

  @media (max-width: 900px) {
    button {
      font-size: 1.5rem;
    }
  }

  @media (max-width: 600px) {
    button {
      font-size: 1rem;
    }
  }

  @media (max-width: 400px) {
    flex-wrap: wrap;

  }
}

main {
  width: calc(100vw - 100px);

  display: grid;
  grid-template-columns: 75% 25%;
  grid-template-rows: 100px auto 2rem;
  grid-template-areas:
    "top top"
    "content-even content-odd"
    "bottom bottom";


  @media (max-width: 900px) {
    grid-template-columns: 100%;
    grid-template-rows: 100px auto auto 10rem;
    grid-template-areas:
      "top"
      "content-even"
      "content-odd"
      "bottom";
  }

  @media (max-width: 600px) {
    grid-template-rows: 150px auto auto 10rem;

    :has(> object) {
      object {
        display: none;
      }

      &::after {
        // height: 20px;
        // width: 200px;
        font-size: 15px;
        background-color: red;
        color: white;
        padding: .5em;
        border-radius: .5em;
        content: "Your screen is too small to display this";
      }
    }
  }

  @media (max-width: 400px) {
    grid-template-rows: 200px auto auto 10rem;
  }

  object {
    background-color: white;

    @media (max-width: 900px) {
      position: relative;
      left: -10px;
    }
  }

  section {
    position: relative;
    padding: 10px;
    border: 1px solid white;
  }

  ;

  section:nth-child(even) {
    grid-area: content-even;
  }

  section:nth-child(odd) {
    grid-area: content-odd;
  }

  section:first-child {
    grid-area: top;

    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    gap: 5px;

    > * {
      text-align: center;
      border: none;
      height: 30px;
      line-height: 30px;
      margin: 0;
      padding: 0;
    }

    @media (max-width: 400px) {
      > * {
        height: 65px;
        line-height: 20px;
        font-size: 20px;
      }
    }
  }

  section:last-child {
    grid-area: bottom;

    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;

    text-align: center;

    display: flex;
    justify-content: center;
    align-items: center;
  }
}

footer {
  height: 100px;
}